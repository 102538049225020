<template>
   <v-dialog v-model="show" max-width="500">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Error, la fecha debe ser igual o posterior a la actual" ref="error_date_noti" />
      <Notification type="error" message="Error, seleccione al menos una actualización" ref="error_updates_noti" />
      <Notification type="error" message="Error, dispositivos" ref="error_devices_noti" />
      <v-card :loading="isloading">
         <v-toolbar elevation="1">
            Actualizar
            <v-spacer></v-spacer>
            <v-btn @click="Update()"><v-icon left>mdi-package-up</v-icon>Actualizar</v-btn>
         </v-toolbar>
         <v-card-text class="mt-6">
            <div class="mb-6" v-if="devices.length > 1">
               <v-chip class="my-1 mr-1" v-for="(device, index) in devices" :key="index">
                  {{ device.nombre }}
               </v-chip>
            </div>
            <v-combobox
               v-model="updates"
               :items="items"
               label="Actualizaciones"
               dense
               multiple
               chips
               return-object
               item-text="nombre"
               item-value="id_update"
               clearable
            >
               <template v-slot:selection="data">
                  <v-chip class="my-1" color="primary" close @click:close="remove(data.item)">
                     {{ data.item.nombre }}
                  </v-chip>
               </template>
            </v-combobox>
            <v-date-picker v-model="date" width="100%" locale="es-es"></v-date-picker>
         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
import moment from "moment";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   data: () => {
      return {
         show: false,
         isloading: false,
         date: moment().format("YYYY-MM-DD"),
         updates: [],
         items: [],
         devices: [],
      };
   },
   methods: {
      async Show(devices) {
         this.updates = [];
         this.items = [];
         this.show = true;
         try {
            this.isloading = true;
            this.devices = devices;
            let res = await this.$store.dispatch("getAvailableUpdates", {});
            if (res) {
               this.items = this.$store.state.available_updates;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      async Update() {
         try {
            this.isloading = true;
            if (moment(this.date).diff(moment(), "days") < 0) {
               this.$refs.error_date_noti.Show();
               return;
            }
            if (this.updates.length <= 0) {
               this.$refs.error_updates_noti.Show();
               return;
            }
            if (this.devices.length <= 0) {
               this.$refs.error_devices_noti.Show();
               return;
            }

            let devices = [];
            let cliente = 0;
            this.devices.forEach((element) => {
               devices.push(element.id_cliente_dispositivo);
               cliente = element.id_cliente;
            });
            let updates = [];
            this.updates.forEach((element) => {
               updates.push(element.id_update);
            });
            let res = await this.$store.dispatch("insertDevicesUpdates", {
               id_cliente_dispositivo: devices,
               id_cliente: cliente,
               id_update: updates,
               fecha: moment(this.date).format("YYYY-MM-DD"),
            });
            if (res == true) {
               this.show = false;
               this.$emit("result_ok");
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      remove(item) {
         const index = this.updates.indexOf(item);
         if (index >= 0) this.updates.splice(index, 1);
      },
   },
};
</script>

<style></style>
