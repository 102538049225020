<template>
   <v-dialog v-model="show" max-width="290">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{ title }}</v-card-title>
         <v-card-text>
            <v-text-field
               v-model="horas"
               :error-messages="horasErrors"
               label="Horas"
               type="number"
               requied
               @input="$v.horas.$touch()"
               @blur="$v.horas.$touch()"
            ></v-text-field>
            <v-text-field
               v-model="notas"
               :error-messages="notasErrors"
               label="Notas"
               required
               @input="$v.notas.$touch()"
               @blur="$v.notas.$touch()"
            ></v-text-field>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="primary" @click="dialogResult_OK" :loading="isloading">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      notas: {
         required,
         maxLength: maxLength(255),
      },
      horas: {
         required,
         isNumber: function () {
            return !isNaN(this.horas);
         },
         absNum: function () {
            return this.horas > 0;
         },
      },
   },
   data: () => {
      return {
         type: "+",
         show: false,
         notas: "",
         horas: 0,
         id_cliente: 0,
         isloading: false,
      };
   },
   computed: {
      title: function () {
         if (this.type == "+") {
            return "Agregar horas";
         } else {
            return "Reducir horas";
         }
      },
      notasErrors() {
         const errors = [];
         if (!this.$v.notas.$dirty) return errors;
         !this.$v.notas.required && errors.push("Requerido");
         !this.$v.notas.maxLength && errors.push("Debe contener maximo 255 caracteres");
         return errors;
      },
      horasErrors() {
         const errors = [];
         if (!this.$v.horas.$dirty) return errors;
         !this.$v.horas.required && errors.push("Requerido");
         !this.$v.horas.absNum && errors.push("Debe ser un valor mayor a cero");
         !this.$v.horas.isNumber && errors.push("Debe ser un numero valido");
         return errors;
      },
   },
   methods: {
      dialogResult_OK: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;

               if (this.type == "+") {
                  res = await this.$store.dispatch("addHoras", {
                     id_cliente: this.id_cliente,
                     horas: this.horas,
                     notas: this.notas,
                  });
               } else if (this.type == "-") {
                  res = await this.$store.dispatch("lessHoras", {
                     id_cliente: this.id_cliente,
                     horas: this.horas,
                     notas: this.notas,
                  });
               } else {
                  throw "No se ha especificado un tipo correcto";
               }

               if (res == true) {
                  this.show = false;
                  this.$emit("result_ok");
               } else {
                  throw res;
               }
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show(type, id_cliente) {
         this.type = type == "+" ? "+" : "-";
         this.id_cliente = id_cliente;
         this.isloading = false;
         (this.horas = ""), (this.notas = ""), this.$v.$reset();
         this.show = true;
      },
   },
};
</script>
