<template>
   <Customers/>
</template>

<script>
import Customers from "../components/Clientes/Customers.vue"

export default {
   components:{
      Customers,
   },
   name: "Clientes",
};
</script>
