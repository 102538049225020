<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="error" message="Acceso denegado" ref="denied_notification" />
      <Horas ref="horas" @result_ok="refreshHoras" />
      <Customer v-model="editedItem" :title="title" ref="customer" @save="save" @close="close" />
      <Dialog message="¿Eliminar cliente?" ref="delete" @result_ok="deleteItemConfirm" />
      <Dialog message="¿Desea (re)generar la API KEY del cliente?" ref="regen_apikey" @result_ok="regen_apikey_confirm" />
      <Devices ref="devices" />
      <v-data-table
         :headers="headers"
         :items="rows"
         :options.sync="options"
         :item-key="'id_cliente'"
         :expanded.sync="expanded"
         :loading="loading"
         :footer-props="{
            'items-per-page-options': [10, 30, 50, 100],
         }"
         :page.sync="page"
         class="ma-3"
         :server-items-length="totalItems"
         single-expand
         show-expand
      >
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn color="primary" v-if="!$store.getters.isMicrosip" @click="newItem" class="d-none d-sm-flex"
                  ><v-icon left>mdi-plus-circle</v-icon>Nuevo cliente</v-btn
               >
               <v-btn color="accent" v-if="!$store.getters.isMicrosip" @click="newItem" class="d-flex d-sm-none" icon small
                  ><v-icon small>mdi-plus-circle</v-icon></v-btn
               >
               <v-btn color="accent" @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                  @input="search = search.slice(0, 30)"
               ></v-text-field>
            </v-toolbar>
         </template>
         <template v-slot:[`item.tipo_cobro`]="{ item }">
            <v-icon small v-if="item.tipo_cobro == 'I'" color="success">mdi-currency-usd</v-icon>
            <v-icon small v-if="item.tipo_cobro == 'H'" color="warning">mdi-clock-outline</v-icon>
         </template>
         <template v-slot:[`item.condicion_pago`]="{ item }">
            {{ item.condicion_pago == "credito" ? "Crédito" : "Contado" }}
         </template>
         <template v-slot:[`item.actions`]="{ item }">
            <div v-if="$store.getters.isMicrosip" class="d-flex flex-space-between align-center">
               <Microsip v-if="item.estatus == 'A'" />
               <Microsip v-if="item.estatus == 'B'" style="filter: grayscale(1)" />
               <v-icon v-if="item.tipo_cobro == 'H'" small class="mx-1" @click="addHoras(item)">mdi-plus</v-icon>
               <v-icon v-if="item.tipo_cobro == 'H'" small class="mx-1" @click="lessHoras(item)">mdi-minus</v-icon>
               <v-icon small class="mx-1" @click="$refs.devices.show(item)">mdi-desktop-classic</v-icon>
            </div>
            <div v-else>
               <v-icon v-if="item.tipo_cobro == 'H'" small class="mx-1" @click="addHoras(item)">mdi-plus</v-icon>
               <v-icon v-if="item.tipo_cobro == 'H'" small class="mx-1" @click="lessHoras(item)">mdi-minus</v-icon>
               <v-icon small class="mx-1" @click="$refs.devices.show(item)">mdi-desktop-classic</v-icon>
               <v-icon small class="mx-1" @click="editItem(item)">mdi-pencil</v-icon>
               <v-icon small class="mx-1" @click="deleteItem(item)">mdi-delete</v-icon>
            </div>
         </template>
         <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="expand-container">
               <v-container style="margin: 0; max-width: unset">
                  <v-row>
                     <v-col cols="2" md="1" class="mr-4 expand-header">Telefono</v-col>
                     <v-col class="expand-content">{{ item.telefono }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="2" md="1" class="mr-4 expand-header">RFC</v-col>
                     <v-col class="expand-content">{{ item.rfc }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="2" md="1" class="mr-4 expand-header">Email</v-col>
                     <v-col class="expand-content">{{ item.email }}</v-col>
                  </v-row>
                  <v-row>
                     <v-col cols="2" md="1" class="mr-4 expand-header">API KEY</v-col>
                     <v-col class="expand-content d-flex justify-start align-center">
                        {{ item.api_key }}
                        <v-btn v-if="item.api_key" icon small @click="copy_api_key(item)">
                           <v-icon small> mdi-content-copy </v-icon>
                        </v-btn>
                        <v-btn icon small @click="renew_api_key(item)">
                           <v-icon small> mdi-sync</v-icon>
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-container>
            </td>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import Devices from "./Customers/Devices.vue";
import Customer from "./Customers/Customer.vue";
import Horas from "./Customers/Horas.vue";
import Dialog from "../Dialog.vue";
import Notification from "../Notification.vue";
import Microsip from "../Icons/Microsip.vue";
import c from "../../const";

export default {
   components: {
      Customer,
      Dialog,
      Notification,
      Microsip,
      Horas,
      Devices,
   },
   data: () => ({
      headers: [
         { text: "ID", value: "id_cliente" },
         { text: "Nombre", value: "nombre" },
         { text: "Empresa", value: "empresa" },
         { text: "Condicion pago", value: "condicion_pago" },
         { text: "Tipo cobro", value: "tipo_cobro" },
         { text: "Horas", value: "horas" },
         { text: "Actions", value: "actions", sortable: false },
         { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      rows: [],
      editedItem: {
         id_cliente: null,
         nombre: "",
         empresa: "",
         telefono: "",
         rfc: "",
         email: "",
         condicion_pago: "",
         tipo_cobro: "",
         horas: 0,
      },
      defaultItem: {
         id_cliente: null,
         nombre: "",
         empresa: "",
         telefono: "",
         rfc: "",
         email: "",
         condicion_pago: "",
         tipo_cobro: "",
         horas: 0,
      },
      options: {
         page: 1,
         itemsPerPage: 10,
         sortBy: [],
         sortDesc: [],
      },
      search: "",
      editedIndex: -1,
      loading: false,
      totalItems: 0,
      page: 1,
      renew_item: {},
   }),
   computed: {
      title() {
         return this.editedIndex === -1 ? "Nuevo cliente" : "Editar cliente";
      },
   },
   watch: {
      options: async function () {
         await this.initialize();
      },
      search: async function () {
         this.page = 1;
         await this.initialize();
      },
   },
   methods: {
      async initialize() {
         try {
            this.loading = true;
            await this.$store.dispatch("getClientes", {
               page: this.options.page,
               itemsPerPage: this.options.itemsPerPage,
               sortBy: this.options.sortBy[0] ?? "",
               sortDesc: this.options.sortDesc[0] ?? false,
               search: this.search,
            });
            this.rows = this.$store.state.clientes.customers;
            this.totalItems = this.$store.state.clientes.totalItems;
            localStorage.setItem("customers_options", JSON.stringify(this.options));
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.loading = false;
         }
      },
      async newItem(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.CREAR_CLIENTES]);
         if (has_permiso == true) {
            this.$refs.customer.show(this.editedItem);
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async addHoras(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.AGREGAR_HORAS]);
         if (has_permiso == true) {
            this.$refs.horas.Show("+", item.id_cliente);
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async lessHoras(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.QUITAR_HORAS]);
         if (has_permiso == true) {
            this.$refs.horas.Show("-", item.id_cliente);
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      refreshHoras() {
         this.$refs.ok_noti.Show();
         this.rows = this.$store.state.clientes.customers;
         this.totalItems = this.$store.state.clientes.totalItems;
      },
      async editItem(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.EDITAR_CLIENTES]);
         if (has_permiso == true) {
            this.editedIndex = this.rows.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.$refs.customer.show(this.editedItem);
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async deleteItem(item) {
         let has_permiso = await this.$store.dispatch("checkPermiso", [c.PERMISOS.ELIMINAR_CLIENTES]);
         if (has_permiso == true) {
            this.editedIndex = this.rows.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.$refs.delete.Show();
         } else {
            this.$refs.denied_notification.Show();
         }
      },
      async deleteItemConfirm() {
         try {
            let res = await this.$store.dispatch("deleteCliente", { id: this.editedItem.id_cliente });
            if (res == true) {
               this.rows.splice(this.editedIndex, 1);
               this.$refs.ok_noti.Show();
            } else {
               throw res;
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.close();
         }
      },
      close() {
         this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
         });
      },
      async save() {
         if (this.editedIndex > -1) {
            Object.assign(this.rows[this.editedIndex], this.editedItem);
         } else {
            this.rows.push(this.editedItem);
         }
         await this.initialize();
         this.close();
      },
      async copy_api_key(item) {
         await navigator.clipboard.writeText(item.api_key);
      },
      async renew_api_key(item) {
         this.renew_item = item;
         this.$refs.regen_apikey.Show();
      },
      async regen_apikey_confirm() {
         let has_permiso = this.$store.getters.isSuperOrAdmin;
         if (has_permiso == true) {
            try {
               let res = await this.$store.dispatch("renewClienteApiKey", { id: this.renew_item.id_cliente });
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.initialize();
               } else {
                  throw res;
               }
            } catch (error) {
               this.$refs.error_noti.ShowPersistent(error);
            }
         } else {
            this.$refs.denied_notification.Show();
         }
      },
   },
   created() {
      try {
         if (localStorage.getItem("customers_options")) {
            this.options = JSON.parse(localStorage.getItem("customers_options"));
         } else {
            this.options = {
               page: 1,
               itemsPerPage: 10,
               sortBy: [],
               sortDesc: [],
            };
            localStorage.setItem("customers_options", JSON.stringify(this.options));
         }
      } catch (error) {
         this.options = {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
         };
         localStorage.setItem("customers_options", JSON.stringify(this.options));
      }
   },
};
</script>

<style scoped>
.expand-header {
   font-weight: 700;
}
.expand-content {
   text-align: left;
}
</style>
