<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>
            {{ title }}
         </v-card-title>
         <v-card-text>
            <form class="d-flex flex-column flex-md-row">
               <div class="d-flex flex-column ma-1" style="width: 100%">
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.nombre.$touch();
                        }
                     "
                     v-model="nombre"
                     label="Nombre"
                     :error-messages="nombreErrors"
                     required
                     @blur="$v.nombre.$touch()"
                  ></v-text-field>
                  <v-text-field
                     @input="
                        () => {
                           input();
                           $v.empresa.$touch();
                        }
                     "
                     v-model="empresa"
                     label="Empresa"
                     :error-messages="empresaErrors"
                     required
                     @blur="$v.empresa.$touch()"
                  ></v-text-field>
                  <v-text-field
                     v-model="telefono"
                     :error-messages="telefonoErrors"
                     :counter="10"
                     label="Telefono"
                     required
                     @input="
                        () => {
                           input();
                           $v.telefono.$touch();
                        }
                     "
                     @blur="$v.telefono.$touch()"
                  ></v-text-field>
                  <v-text-field
                     v-model="rfc"
                     :error-messages="rfcErrors"
                     label="RFC"
                     required
                     @input="
                        () => {
                           input();
                           $v.rfc.$touch();
                        }
                     "
                     @blur="$v.rfc.$touch()"
                  ></v-text-field>
               </div>
               <div class="d-flex flex-column ma-1" style="width: 100%">
                  <v-text-field
                     v-model="email"
                     :error-messages="emailErrors"
                     label="Email"
                     required
                     @input="
                        () => {
                           input();
                           $v.email.$touch();
                        }
                     "
                     @blur="$v.email.$touch()"
                  ></v-text-field>
                  <v-select
                     @input="
                        () => {
                           input();
                           $v.condicion_pago.$touch();
                        }
                     "
                     v-model="condicion_pago"
                     return-object
                     label="Condición de pago"
                     :items="condiciones_pago"
                     :error-messages="condicion_pagoErrors"
                     required
                     @blur="$v.condicion_pago.$touch()"
                  ></v-select>
                  <v-select
                     @input="
                        () => {
                           input();
                           $v.tipo_cobro.$touch();
                        }
                     "
                     v-model="tipo_cobro"
                     return-object
                     label="Tipo de cobro"
                     :items="tipos_cobro"
                     :error-messages="tipo_cobroErrors"
                     required
                     @blur="$v.tipo_cobro.$touch()"
                  ></v-select>
                  <v-text-field
                     v-model="horas"
                     :error-messages="horasErrors"
                     label="Horas"
                     :disabled="isEdit || tipo_cobro.value == 'I'"
                     type="number"
                     requied
                     @input="
                        () => {
                           input();
                           $v.horas.$touch();
                        }
                     "
                     @blur="$v.horas.$touch()"
                  ></v-text-field>
               </div>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, minLength, integer } from "vuelidate/lib/validators";
import Notification from "../../Notification.vue";

export default {
   components: {
      Notification,
   },
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         name: function () {
            return /^[a-zA-Z_ ]*$/.test(this.nombre);
         },
      },
      empresa: { maxLength: maxLength(50), required },
      telefono: {
         required,
         maxLength: maxLength(10),
         minLength: minLength(10),
         integer,
      },
      rfc: { required, maxLength: maxLength(30) },
      email: { required, email, maxLength: maxLength(320) },
      condicion_pago: { required },
      tipo_cobro: { required },
      horas: {
         required,
         is_integer: function () {
            return /^-?[0-9]+$/.test(this.horas);
         },
         isNumber: function () {
            return !isNaN(this.horas);
         },
         absNum: function () {
            return this.horas >= 0;
         },
      },
   },
   props: {
      value: Object,
      title: String,
   },
   model: {
      prop: "value",
      event: "input",
   },
   data: function () {
      return {
         id_cliente: this.value.id_cliente,
         nombre: this.value.nombre,
         empresa: this.value.empresa,
         telefono: this.value.telefono,
         rfc: this.value.rfc,
         email: this.value.email,
         condicion_pago: this.value.condicion_pago,
         tipo_cobro: this.value.tipo_cobro,
         horas: this.value.horas,
         dialog: false,
         isloading: false,
         condiciones_pago: [
            {
               value: "contado",
               text: "Contado",
            },
            {
               value: "credito",
               text: "Crédito",
            },
         ],
         tipos_cobro: [
            {
               value: "I",
               text: "Importe",
            },
            {
               value: "H",
               text: "Horas",
            },
         ],
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertCliente", {
                     nombre: this.nombre,
                     empresa: this.empresa,
                     telefono: this.telefono,
                     rfc: this.rfc,
                     email: this.email,
                     condicion_pago: this.condicion_pago.value,
                     tipo_cobro: this.tipo_cobro.value,
                     horas: this.horas,
                  });
               } else {
                  res = await this.$store.dispatch("updateCliente", {
                     id_cliente: this.id_cliente,
                     nombre: this.nombre,
                     empresa: this.empresa,
                     telefono: this.telefono,
                     rfc: this.rfc,
                     email: this.email,
                     condicion_pago: this.condicion_pago.value,
                     tipo_cobro: this.tipo_cobro.value,
                     horas: this.horas,
                  });
               }
               if (res == true) {
                  this.$refs.ok_noti.Show();
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.id_cliente = i.id_cliente;
         this.nombre = i.nombre;
         this.empresa = i.empresa;
         this.telefono = i.telefono;
         this.rfc = i.rfc;
         this.email = i.email;
         this.condicion_pago =
            i.condicion_pago == "contado"
               ? {
                    value: "contado",
                    text: "Contado",
                 }
               : {
                    value: "credito",
                    text: "Crédito",
                 };
         this.tipo_cobro =
            i.tipo_cobro == "I"
               ? {
                    value: "I",
                    text: "Importe",
                 }
               : {
                    value: "H",
                    text: "Horas",
                 };
         this.horas = i.horas;
         this.dialog = true;
      },
      input: function (e) {
         this.$emit("input", {
            id_cliente: this.id_cliente,
            nombre: this.nombre,
            empresa: this.empresa,
            telefono: this.telefono,
            rfc: this.rfc,
            email: this.email,
            condicion_pago: this.condicion_pago.value,
            tipo_cobro: this.tipo_cobro.value,
            horas: this.horas,
         });
      },
   },
   computed: {
      isEdit() {
         if (this.id_cliente) {
            return true;
         } else {
            return false;
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.name && errors.push("Debe contener caracteres alfabeticos");
         return errors;
      },
      empresaErrors() {
         const errors = [];
         if (!this.$v.empresa.$dirty) return errors;
         !this.$v.empresa.maxLength && errors.push("Debe contener maximo 50 caracteres");
         !this.$v.empresa.required && errors.push("Requerido");
         return errors;
      },
      telefonoErrors() {
         const errors = [];
         if (!this.$v.telefono.$dirty) return errors;
         !this.$v.telefono.required && errors.push("El telefono es requerido");
         !this.$v.telefono.minLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.maxLength && errors.push("Debe contener 10 caracteres");
         !this.$v.telefono.integer && errors.push("Debe contener caracteres numericos");
         return errors;
      },
      rfcErrors() {
         const errors = [];
         if (!this.$v.rfc.$dirty) return errors;
         !this.$v.rfc.required && errors.push("Required.");
         !this.$v.rfc.maxLength && errors.push("Debe contener maximo 30 caracteres");
         return errors;
      },
      emailErrors() {
         const errors = [];
         if (!this.$v.email.$dirty) return errors;
         !this.$v.email.email && errors.push("Debe ser un email valido");
         !this.$v.email.required && errors.push("El email es requerido");
         !this.$v.email.maxLength && errors.push("El maximo posible es de 320");
         return errors;
      },
      condicion_pagoErrors() {
         const errors = [];
         if (!this.$v.condicion_pago.$dirty) return errors;
         !this.$v.condicion_pago.required && errors.push("Requerido");
         return errors;
      },
      tipo_cobroErrors() {
         const errors = [];
         if (!this.$v.tipo_cobro.$dirty) return errors;
         !this.$v.tipo_cobro.required && errors.push("Requerido");
         return errors;
      },
      horasErrors() {
         const errors = [];
         if (!this.$v.horas.$dirty) return errors;
         !this.$v.horas.required && errors.push("Requerido");
         !this.$v.horas.is_integer && errors.push("Debe ser un valor entero");
         !this.$v.horas.absNum && errors.push("Debe ser un valor positivo o cero");
         !this.$v.horas.isNumber && errors.push("Debe ser un numero valido");
         return errors;
      },
   },
};
</script>
