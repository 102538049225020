var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('Notification',{ref:"ok_noti",attrs:{"type":"success","message":"Información actualizada"}}),_c('Notification',{ref:"error_noti",attrs:{"type":"error","message":"Error"}}),_c('v-card',{attrs:{"loading":_vm.isloading}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('form',{staticClass:"d-flex flex-column flex-md-row"},[_c('div',{staticClass:"d-flex flex-column ma-1",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"Nombre","error-messages":_vm.nombreErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.nombre.$touch();
                     },"blur":function($event){return _vm.$v.nombre.$touch()}},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}}),_c('v-text-field',{attrs:{"label":"Empresa","error-messages":_vm.empresaErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.empresa.$touch();
                     },"blur":function($event){return _vm.$v.empresa.$touch()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}}),_c('v-text-field',{attrs:{"error-messages":_vm.telefonoErrors,"counter":10,"label":"Telefono","required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.telefono.$touch();
                     },"blur":function($event){return _vm.$v.telefono.$touch()}},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),_c('v-text-field',{attrs:{"error-messages":_vm.rfcErrors,"label":"RFC","required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.rfc.$touch();
                     },"blur":function($event){return _vm.$v.rfc.$touch()}},model:{value:(_vm.rfc),callback:function ($$v) {_vm.rfc=$$v},expression:"rfc"}})],1),_c('div',{staticClass:"d-flex flex-column ma-1",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"error-messages":_vm.emailErrors,"label":"Email","required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.email.$touch();
                     },"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-select',{attrs:{"return-object":"","label":"Condición de pago","items":_vm.condiciones_pago,"error-messages":_vm.condicion_pagoErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.condicion_pago.$touch();
                     },"blur":function($event){return _vm.$v.condicion_pago.$touch()}},model:{value:(_vm.condicion_pago),callback:function ($$v) {_vm.condicion_pago=$$v},expression:"condicion_pago"}}),_c('v-select',{attrs:{"return-object":"","label":"Tipo de cobro","items":_vm.tipos_cobro,"error-messages":_vm.tipo_cobroErrors,"required":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.tipo_cobro.$touch();
                     },"blur":function($event){return _vm.$v.tipo_cobro.$touch()}},model:{value:(_vm.tipo_cobro),callback:function ($$v) {_vm.tipo_cobro=$$v},expression:"tipo_cobro"}}),_c('v-text-field',{attrs:{"error-messages":_vm.horasErrors,"label":"Horas","disabled":_vm.isEdit || _vm.tipo_cobro.value == 'I',"type":"number","requied":""},on:{"input":() => {
                        _vm.input();
                        _vm.$v.horas.$touch();
                     },"blur":function($event){return _vm.$v.horas.$touch()}},model:{value:(_vm.horas),callback:function ($$v) {_vm.horas=$$v},expression:"horas"}})],1)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }