<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Notification type="error" message="Error" ref="error_noti" />
      <Update ref="update" @result_ok="updatesMaked" />
      <Dialog message="¿Eliminar dispositivos?" ref="delete_device" @result_ok="deleteDeviceConfirm" />
      <Dialog message="¿Eliminar actualización?" ref="delete_update" @result_ok="deleteUpdateConfirm" />
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card :loading="isloading">
            <v-toolbar elevation="2" color="secondary">
               <v-btn icon @click="close">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>Dispositivos - {{ cliente.nombre }}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn color="primary" :disabled="!selected" @click="updateAll" :loading="isloading"
                  ><v-icon left>mdi-package-up</v-icon>Actualizar</v-btn
               >
            </v-toolbar>
            <v-card-text>
               <v-checkbox v-if="devices.length > 0" color="accent" label="Seleccionar todos" v-model="selectAll" @change="selecting"></v-checkbox>
               <v-expansion-panels class="mb-2">
                  <v-expansion-panel v-for="(device, index) in devices" :key="index">
                     <v-expansion-panel-header color="secondary" dense>
                        <v-row>
                           <v-col style="height: 0" align-self="center" class="d-flex align-center">
                              <v-checkbox v-model="device.selected" @change="selecting"></v-checkbox>
                              <span style="font-weight: bold">
                                 {{ device.nombre }}
                              </span>
                           </v-col>
                           <v-col cols="3" md="2" class="d-flex justify-end align-center">
                              <v-tooltip bottom>
                                 <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="accent" class="mr-1" @click="updateDevice(device)" v-bind="attrs" v-on="on" icon
                                       ><v-icon>mdi-package-up</v-icon></v-btn
                                    >
                                 </template>
                                 <span>Actualizar equipo</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                 <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="accent" class="mr-1" @click="deleteDevice(device)" v-bind="attrs" v-on="on" icon
                                       ><v-icon>mdi-close</v-icon></v-btn
                                    >
                                 </template>
                                 <span>Eliminar equipo</span>
                              </v-tooltip>
                           </v-col>
                        </v-row>
                     </v-expansion-panel-header>
                     <v-expansion-panel-content class="pt-3">
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">Hardware ID: </span> {{ device.hardware_id }}
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">ID: </span> {{ device.id_cliente_dispositivo }}
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">Descripcion: </span> {{ device.descripcion }}
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">CPU: </span> {{ device.procesador }} <v-icon right>mdi-memory</v-icon>
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">RAM: </span> {{ device.ram }} <v-icon right>mdi-chip</v-icon>
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">Almacenamiento: </span> {{ device.almacenamiento }}
                           <v-icon right>mdi-disc</v-icon>
                        </div>
                        <div class="d-flex flex-row align-center">
                           <span class="mr-2" style="font-weight: bold">Arquitectura: </span>
                           <v-icon>{{ device.x64 == "S" ? "mdi-cpu-64-bit" : "mdi-cpu-32-bit" }}</v-icon>
                        </div>
                        <div class="d-flex flex-column flex-sm-row mt-2">
                           <v-chip style="max-width: max-content" class="ma-1" v-for="(update, indx) in device.updates" :key="indx">
                              {{ update.nombre }}
                              <span style="display: inline-block; width: 8px"></span>
                              <span style="font-weight: bold">{{ display_fecha(update.fecha) }}</span>
                              <v-btn class="ml-1" @click="deleteUpdate(update)" x-small icon><v-icon x-small> mdi-close</v-icon></v-btn>
                           </v-chip>
                        </div>
                     </v-expansion-panel-content>
                  </v-expansion-panel>
               </v-expansion-panels>
            </v-card-text>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import Notification from "../../Notification.vue";
import Update from "./Update.vue";
import c from "../../../const";
import moment from "moment";
import Dialog from "../../Dialog.vue";

export default {
   components: {
      Notification,
      Update,
      Dialog,
   },
   data: function () {
      return {
         dialog: false,
         isloading: false,
         devices: [],
         cliente: {},
         selected: false,
         selectAll: false,
         updateToDelete: {},
         deviceToDelete: {},
      };
   },
   watch: {
      selectAll: function () {
         this.devices.forEach((element) => {
            element.selected = this.selectAll;
         });
      },
   },
   methods: {
      updatesMaked: function () {
         this.$refs.ok_noti.Show();
         this.show(this.cliente);
      },
      updateAll: async function () {
         this.$refs.update.Show(this.devices.filter((elem) => elem.selected == true));
      },
      updateDevice: async function (device) {
         this.$refs.update.Show([device]);
      },
      deleteUpdate: function (update) {
         this.updateToDelete = update;
         this.$refs.delete_update.Show();
      },
      deleteDevice: function (device) {
         this.deviceToDelete = device;
         this.$refs.delete_device.Show();
      },
      deleteDeviceConfirm: async function () {
         try {
            this.isloading = true;
            let res = await this.$store.dispatch("deleteDevice", { id: this.deviceToDelete.id_cliente_dispositivo });
            if (res) {
               this.$refs.ok_noti.Show();
               this.show(this.cliente);
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      deleteUpdateConfirm: async function () {
         try {
            this.isloading = true;
            let res = await this.$store.dispatch("deleteDeviceUpdate", { id: this.updateToDelete.id_cliente_update });
            if (res) {
               this.$refs.ok_noti.Show();
               this.show(this.cliente);
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
      },
      show: async function (cliente) {
         this.dialog = true;
         try {
            this.isloading = true;
            this.cliente = cliente;
            let res = await this.$store.dispatch("getDevices", { id: cliente.id_cliente });
            if (res) {
               let device = {};
               this.devices = [];
               this.$store.state.devices.forEach((element) => {
                  device = element;
                  device.selected = false;
                  this.devices.push(device);
               });
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      display_fecha: function (str) {
         return moment(str).format("DD-MM-YYYY");
      },
      selecting: function () {
         this.$nextTick(() => {
            this.selected = this.devices.filter((elem) => elem.selected == true).length > 0;
         });
      },
   },
};
</script>
